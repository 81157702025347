<template>
  <v-navigation-drawer
    class="ml-0 ml-sm-3 align-self-center transparent"
    width="160"
    permanent
  >
    <!--  상단  -->
    <template v-slot:prepend>

      <!--  현재 접속 인원 수 표시  -->
      <v-list-item
        v-if="gc_user.admin_level < 50"
        class="px-3 transparent"
        dense
        dark
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content :style="`color: ${colors.default}`">
          {{ accessorCount }} {{ gc_langText.stream.text.users[gc_lang] }}
        </v-list-item-content>
      </v-list-item>

    </template>

    <!--  중단: 탭 리스트  -->
    <v-list class="pa-0">

      <!--  모듈 리스트  -->
      <v-list-item
        v-for="(tab, i) in tabList"
        :key="`stream-bar-${i}`"
        :input-value="tabIdx === i"
        class="flex-column mb-3 pa-0"
        @click="$emit('setTab', i)"
        dark
      >
        <!--  렌더링 최적화를 위해 v-if 를 사용하지 않는다.  -->
        <v-img
          v-show="tabIdx === i"
          :src="tab.icon_on"
          min-height="32"
          max-height="64"
          contain
        />
        <v-img
          v-show="tabIdx !== i"
          :src="tab.icon"
          min-height="32"
          max-height="64"
          contain
        />
        <span :style=" `color: ${tabIdx === i ? colors.active : colors.default}` ">
          {{ tab[`name${gc_lang_prefix}`] }}
        </span>
      </v-list-item>

    </v-list>

    <!--  하단  -->
    <template v-slot:append>
      <v-list>

        <!--  채팅창 토글 버튼  -->
        <v-list-item
          v-if="chatEnabled"
          @click="$emit('toggleChat')"
          dense
          dark
        >
          <v-list-item-icon>
            <v-badge
              color="red"
              :content="chatBadge > 99 ? '+99' : chatBadge"
              :value="chatBadge"
              overlap
              dot
            >
              <v-icon>mdi-chat-outline</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content :style="`color: ${colors.default}`">
            {{ gc_langText.stream.btn.chat[gc_lang] }}
          </v-list-item-content>
        </v-list-item>

        <!--  탭: Exit  -->
        <v-list-item
          to="/"
          dense
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content :style="`color: ${colors.default}`">
            {{ gc_langText.stream.btn.exit[gc_lang] }}
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: "StreamBar",

  props: {
    tabIdx: {
      type: Number,
      default: 0
    },

    tabList: {
      type: Array,
      default: () => ([])
    },

    // 대기 및 활성화 여부에 따른 텍스트 컬러 객체 (헥스 코드)
    colors: {
      type: Object,
      default: () => ({})
    },

    chatEnabled: {
      type: Boolean,
      default: false
    },

    chatBadge: {
      type: Number,
      default: 0
    },

    accessorCount: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="sass" scoped>
</style>