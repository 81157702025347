<template>
  <v-card
    class="transparent c-w-100-stream"
    flat
    tile
  >

    <!--  영상 iframe  -->
    <div
      id="frameBox"
      :class="isFullscreen ? 'c-full' : ''"
      ref="frameBox"
      @fullscreenchange="fullscreenchange"
      @webkitfullscreenchange="fullscreenchange"
    >
      <div class="c-fluidMedia">
        <iframe
          class="edit_mobile_iframe"
          :src="src"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          scrolling="no"
          allow="autoplay"
        />

        <!--  질문  -->
        <v-sheet
          v-if="question"
          class="ma-0 pa-2 pa-sm-4 pa-md-8 d-flex flex-column c-popup-question"
          tile
        >
          <div class="text-h6 text-sm-h5 text-md-h4 c-question-header">Q&A</div>
          <div class="text-body-1 text-sm-h6 text-md-h5 pt-0 pt-sm-4 pt-md-8 flex-grow-1 c-question-content">{{ question }}</div>
        </v-sheet>

        <!--  전체화면 버튼  -->
        <v-btn
          @click="fullScreenToggle"
          class="c-btn-fullscreen-toggle"
          color="white"
          elevation="0"
        >
          <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>
      </div>
    </div>

  </v-card>
</template>

<script>
export default {
  name: "StreamTabWebinar",

  props: {
    src: {
      type: String,
      default: ''
    },

    question: {
      type: String,
      default: ''
    }
  },

  data: ()=>({
    // 풀스크린 상태 여부
    isFullscreen: false
  }),

  methods: {
    // 비디오 풀 스크린
    fullScreenToggle() {
      const el = this.$refs.frameBox

      // 풀스크린 상태라면
      if (this.isFullscreen) {
        if (document.exitFullscreen) { document.exitFullscreen() }
        else if (document.webkitExitFullscreen) { document.webkitExitFullscreen() }
        else if (document.mozCancelFullScreen) { document.mozCancelFullScreen() }
        else if (document.msExitFullscreen) { document.msExitFullscreen() }
      }
      // 풀스크린 요청이라면
      else {
        if (el.requestFullscreen) { el.requestFullscreen() }
        else if (el.webkitRequestFullScreen) { el.webkitRequestFullScreen() }
        else if (el.mozRequestFullScreen) { el.mozRequestFullScreen() }
        else if (el.msRequestFullscreen) { el.msRequestFullscreen() }
      }
    },

    // 풀 스크린 체인지 이벤트 헨들러
    fullscreenchange() {
      this.isFullscreen = !this.isFullscreen
    }
  }
}
</script>

<style lang="sass" scoped>
#frameBox
  position: relative
  padding-bottom: 56.25%
  height: 0
  .c-fluidMedia:hover, .c-fluidMedia:active
    .c-btn-fullscreen-toggle
      opacity: 1
  .c-fluidMedia
    position: relative
    padding-bottom: 56.25%
    height: 0
    overflow: hidden
    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    .c-popup-question
      position: absolute
      top: 15.99%
      left: 25.75%
      right: 1.33%
      bottom: 11.28%
      .c-question-header
        border-bottom: thick solid #00AEAF
      .c-question-content
        white-space: pre-line !important
        overflow: auto
    .c-btn-fullscreen-toggle
      opacity: 0
      position: absolute
      padding: 0
      min-width: auto
      width: 36px
      height: 36px
      right: 2px
      bottom: 2px
      border-radius: 2px
#frameBox.c-full
  position: relative
  padding-bottom: 0
  width: 100vw
  height: 100vh
  .c-fluidMedia
    position: absolute
    top: 50%
    left: 50%
    padding-bottom: 0
    transform: translate(-50%, -50%)
    width: 100%
    height: 100%
    max-width: calc(100vh * 1.7776)
    max-height: calc(100vw / 1.7776)
    iframe
      max-width: calc(100vh * 1.7776)
@media (max-width: 1024px)
  .c-btn-fullscreen-toggle
    opacity: 1 !important
</style>