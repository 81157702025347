<template>
  <v-card
    v-show="show"
    id="c-chat-window"
    class="cyan lighten-4"
    width="300"
    max-width="300"
    tile
  >
    <v-system-bar
      color="cyan"
      dark
    >
      <v-spacer/>
      <v-icon @click="$emit('toggleChat')">
        mdi-window-close
      </v-icon>
    </v-system-bar>

    <!--  메세지 불러오는 중 로딩박스  -->
    <v-progress-linear
      v-if="messages === null"
      class="mb-n1"
      color="cyan"
      height="4"
      indeterminate
    />

    <!--  메세지 출력 박스  -->
    <v-card-text
      v-else
      id="c-chat-texts"
      class="pa-0"
      @scroll="scroll"
    >
      <v-list class="pa-0 pt-3 transparent">

        <!--  메세지 리스트  -->
        <v-list-item
          v-for="(message, i) in messages"
          :key="`chat-messagei${i}-${message.id}`"
        >
          <v-list-item-content
            v-if="message.user"
            :class="`d-block pt-0 ${gc_user.id === message.user_id ? 'text-right' : ''}`"
          >
            <v-list-item-title class="mb-1 c-chat-name">
              {{ message.user.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="c-chat-message">
              {{ message.content }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-btn
        v-show="showBtnDown"
        elevation="2"
        class="c-btn-down"
        color="cyan"
        @click="scrollToBottom(true)"
        absolute
        right
        dark
        small
        fab
      >
        <v-icon>mdi-arrow-down</v-icon>
      </v-btn>
    </v-card-text>

    <!--  메세지 입력 박스  -->
    <v-card-actions class="white">
      <v-text-field
        v-model="form.text"
        ref="messageInput"
        placeholder="Message"
        append-outer-icon="mdi-send"
        maxlength="128"
        @keydown.enter="api_sendChatMessage"
        @click:append-outer="api_sendChatMessage"
        :loading="form.requesting"
        :disabled="form.requesting"
        hide-details
        outlined
        dense
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "StreamChat",

  props: {
    // 채팅 메세지 배열 (null: 호출한 적 없음, Array: 호출한 적 있음)
    messages: null,

    show: {
      type: Boolean,
      default: false
    },

    event_id: {
      type: Number,
      default: -1
    }
  },

  data: () => ({
    // 채팅 입력 고나련 객체
    form: {
      // 입력한 메세지
      text: '',
      // 메세지 전송 요청 중
      requesting: false
    },

    // 메세지 갱신 시 오토 스크롤
    autoScroll: true,

    // 메세지 입력창으로 포커스 필요 여부
    needFocusToInput: false,

    // 채팅 최하단으로 버튼 보일지 여부
    showBtnDown: false
  }),

  updated() {
    this.scrollToBottom()
    this.scroll()
    this.focusToInput()
  },

  methods: {
    // 스크롤 시 오토 스크롤 여부 재판단
    scroll() {
      const target = document.getElementById('c-chat-texts')

      if (!target) { return }

      const offsetHeight = target.offsetHeight
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop

      // 오토 스크롤 필요 여부 판단
      const bottom = scrollHeight <= (offsetHeight + scrollTop)
      this.autoScroll = bottom

      // 스크롤 다운 버튼 보여줄지 판단 (n 라인 이상 스크롤 업 되있는 상태)
      const lines = 70 * 5 // 70px 를 하나의 라인으로 관주
      const tooFall = scrollHeight > (offsetHeight + scrollTop + lines)
      this.showBtnDown = tooFall
    },

    // 스크롤 바텀으로 이동
    scrollToBottom(force = false) {
      const el = document.getElementById('c-chat-texts')

      if (!el) { return }

      if (force || this.autoScroll) {
        el.scrollTo(0, el.scrollHeight)
      }
    },

    // 메세지 입력란으로 포커스
    focusToInput() {
      this.needFocusToInput = false
      this.$refs.messageInput.focus()
    }
  }
}
</script>

<style lang="sass" scoped>
#c-chat-window
  position: absolute
  right: 172px
  bottom: 64px
  outline: 1px solid #FFFFFF
  z-index: 2
#c-chat-texts
  min-height: 240px
  height: 240px
  max-height: 240px
  overflow: auto
.c-chat-name
  font-size: .875rem !important
.c-chat-message
  display: inline-block
  background-color: #FFFFFF
  padding: 8px
  max-width: 80%
  white-space: break-spaces
  text-align: left
  border-radius: 8px
  font-size: .75rem !important
.c-btn-down
  right: 24px
  bottom: 72px
@media (max-width: 490px)
  #c-chat-window
    right: 10px
    bottom: 112px
</style>