<template>
  <v-dialog
      v-model="show"
      transition="dialog-bottom-transition"
      width="480"
      persistent
      scrollable
  >
    <!--  질문 알림창  -->
    <v-card tile>

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          class="c-tt-none"
          color="black"
          @click="show = false"
          :disabled="requesting"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-title class="headline mb-2 py-0">
        Question Edit
      </v-card-title>

      <!--  질문 입력란  -->
      <v-card-text class="pt-2 pb-0">
        <v-form
          ref="form"
          v-model="formValid"
        >

          <!--  발표자 선택  -->
          <v-text-field
            v-model="form.presenter"
            :label="gc_langText.stream.text.selectPresenter[gc_lang]"
            :rules="[gc_rules.required]"
            readonly
            outlined
            dense
          />

          <!--  질문 내용  -->
          <v-textarea
            v-model="form.content"
            class="rounded-0"
            :label="gc_langText.stream.text.questionContent[gc_lang]"
            :rules="[gc_rules.required]"
            :disabled="requesting"
            outlined
            dense
          />

        </v-form>
      </v-card-text>

      <!--  수정 버튼  -->
      <v-card-actions class="justify-center pt-0 pb-4">
        <v-btn
          class="px-16 c-tt-none"
          color="cyan"
          elevation="0"
          :loading="requesting"
          @click="submit"
          large
          dark
          tile
        >
          {{ gc_langText.stream.btn.edit[gc_lang] }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StreamTabQuestionEditDialog",

  props: {
    _show: {
      type: Boolean,
      default: false
    },

    question: {
      type: Object,
      default: () => ({})
    },
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,

    // 질문 전송중
    requesting: false,

    // 입력란
    form: {
      // 질문 대상
      presenter: '',

      // 질문 내용
      content: '',
    },

    // 입력란 유효성 검사
    formValid: true
  }),

  watch: {
    _show(val) {
      const form = this.$refs.form
      this.show = val

      form && form.resetValidation()

      // 팝업이 오픈 될 때 input 데이터들 삽입
      if (val) {
        // 빈객체일 경우 리턴
        if (!this.question.id) { return }
        const lang = this.gc_lang_prefix

        this.form.presenter = this.question.speakers[`name${lang}`]
        this.form.content = this.question.contents
      }
    },

    show(val) {
      !val && this.$emit('questionEditClose')
    }
  },

  methods: {
    // 질문 수정 전송
    submit() {
      this.$refs.form.validate()

      this.formValid && this.api_submitQuestionEdit()
    }
  }
}
</script>

<style scoped>

</style>