<template>
  <v-list
    class="transparent pa-0 c-w-100-stream"
    three-line
    flat
  >
    <!--  결과가 없을 때  -->
    <v-list-item
      v-if="list.length === 0"
      class="white"
    >
      <v-list-item-content class="justify-center">
        {{ gc_langText.stream.text.questionNothing[gc_lang] }}
      </v-list-item-content>
    </v-list-item>

    <!--  결과가 있을 때  -->
    <v-list-item-group
      v-else
      v-model="selected"
      multiple
    >

      <!--  질문 리스트  -->
      <v-list-item
        class="white c-mb pl-1"
        v-for="(item, i) in list"
        :key="`q-list-${i}`"
      >

        <!--  버튼 그룹: 좌측  -->
        <v-list-item-action class="flex-row align-self-center align-center">

          <!--  채크박스: 하위 관리자에게 노출여부 채크박스  -->
          <!--  하위 관리자 미사용으로 상시 비활성화 시킨 상태  -->
          <v-btn
            v-if="gc_user.admin_level === 0 && questionFilter && false"
            @click.stop="$emit('showFilter', i)"
            :color="item.is_filtering ? 'primary' : 'secondary'"
            dark
            icon
          >
            <v-icon v-if="item.is_filtering">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-btn>

          <!--  버튼: 질문 수정  -->
          <v-btn
            @click.stop="$emit('questionEditOpen', i)"
            color="secondary"
            dark
            icon
          >
            <v-icon>mdi-border-color</v-icon>
          </v-btn>
        </v-list-item-action>

        <!--  컨텐츠 박스: 질문타겟 및 내용  -->
        <v-list-item-content class="align-content-start">
          <!--  타겟: 질문 받는 사람  -->
          <v-list-item-title class="mt-1 mb-3">
            {{ item.speakers ? item.speakers[`name${gc_lang_prefix}`] : '-' }}
          </v-list-item-title>
          <!--  컨텐츠: 내용  -->
          <v-list-item-subtitle class="body-1 c-view-all">
            {{ item.contents }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <!--  버튼 그룹: 우측  -->
        <v-list-item-action class="flex-row align-self-center align-center">
          <!--  버튼: 질문 노출여부  -->
          <v-btn
            @click.stop="$emit('showQuestion', i)"
            :color="item.is_use_subtitle ? 'success' : 'secondary'"
            class="c-tt-none"
            min-height="48"
            elevation="0"
            tile
            dark
          >
            질문 노출
            <br>
            {{ item.is_use_subtitle ? 'On' : 'Off' }}
          </v-btn>

          <!--  버튼: 채크박스  -->
          <v-btn
            @click.stop="$emit('showChecked', i)"
            :color="item.is_checked ? 'primary' : 'secondary'"
            class="ml-3"
            dark
            icon
          >
            <v-icon v-if="item.is_checked">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-btn>
        </v-list-item-action>

      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "StreamTabQuestionList",

  props: {
    list: {
      type: Array,
      default: () => ([])
    },

    questionFilter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    // 선택된 질문들
    selected: [],

    // 웨비나에 보여야 할 질문
    showQuestionIdx: null
  }),

  watch: {
    // 리스트 배열이 업데이트 될 경우 selected 배열 업데이트
    list(arr) {
      if (arr.length) {
        this.selected = arr.map( (item, i) => {
          if (item.is_filtering) { return i }
        }).filter( item => item !== undefined )
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.c-mb
  margin-bottom: 12px !important
.c-view-all
  white-space: pre-line !important
  display: block !important
  -webkit-box-orient: unset !important
  -webkit-line-clamp: unset !important
</style>