<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card
      class="px-16 py-4 mt-16"
      tile
    >

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="black"
          @click="show = false"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <!--  iframe 박스  -->
      <v-card-text class="pa-0 c-survey-frame">
        <iframe
          v-if="_show"
          :src="src"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        />
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StreamTabIFrame",

  props: {
    // 부모로부터 팝업 토글 여부를 받는다 (부모 속성이므로 직접 컨트롤 불가)
    _show: {
      type: Boolean,
      default: false
    },

    src: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,
  }),

  watch: {
    // 부모로부터 전달 받은 속성 변경 값 자체 속성 값과 링크
    _show(val) {
      this.show = val
    },

    // 자체 속성값 "닫기" 로 변경시 부모 메서드 호출
    show(val) {
      !val && this.$emit('linkClose')
    }
  }
}
</script>

<style lang="sass" scoped>
.c-survey-frame
  position: absolute
  top: 52px
  left: 0
  right: 0
  bottom: 0
</style>