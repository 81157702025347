<template>
  <v-row
    v-if="gc_pageLoaded"
    class="align-self-stretch indigo c-bd-b"
  >
    <v-col
      v-if="gc_pageLoaded"
      class="d-flex flex-column flex-sm-row c-layout"
    >

      <!--  탭: 0 - Webinar  -->
      <stream-tab-webinar
        v-show="showType === 'webinar'"
        :question="question.active"
        :src="streamSrc"
      />

      <!--  탭: 1 - Slide  -->
      <stream-tab-slide
        v-show="showType === 'slide'"
        :slide="slide.list"
      />

      <!--  탭: 2 - Question 리스트  -->
      <stream-tab-question-list
        v-show="showType === 'questiontospeaker'"
        :list="question.list"
        :questionFilter="!!info.is_question_filter"
        @showQuestion="showQuestion"
        @showFilter="showFilter"
        @showChecked="showChecked"
        @questionEditOpen="questionEditOpen"
      />

      <!--  팝업 탭: 3 - iframe 링크  -->
      <stream-tab-i-frame
        :_show="linkDialog.show"
        :src="linkDialog.src"
        @linkClose="linkClose"
      />

      <!--  우측 메뉴 리스트  -->
      <stream-bar
        :tabIdx="tabIdx"
        :tabList="tabList"
        :colors="colors"
        :chatEnabled="!!info.is_used_chat"
        :chatBadge="chatPopup.badge"
        :accessorCount="accessorCount"
        @setTab="setTab"
        @toggleChat="toggleChat"
      />

      <!--  팝업: 채팅  -->
      <stream-chat
        v-if="info.is_used_chat"
        :show="chatPopup.show"
        :event_id="info.id || -1"
        :messages="chatPopup.messages"
        @toggleChat="toggleChat"
        @pushLocalMessage="pushLocalMessage"
      />

    </v-col>

    <stream-tab-question-dialog
      :_show="questionDialog.show"
      :module_id="question.module_id"
      :speaker_module_id="question.speaker_module_id"
      @questionClose="questionClose"
    />

    <stream-tab-question-edit-dialog
      :_show="questionDialogEdit.show"
      :question="questionDialogEdit.question"
      @questionEditClose="questionEditClose"
    />

  </v-row>
</template>

<script>
import StreamBar from "../components/stream/StreamBar"
import StreamTabWebinar from "../components/stream/StreamTabWebinar"
import StreamTabSlide from "../components/stream/StreamTabSlide"
import StreamTabQuestionDialog from "../components/stream/StreamTabQuestionDialog"
import StreamTabQuestionEditDialog from "../components/stream/StreamTabQuestionEditDialog"
import StreamTabQuestionList from "../components/stream/StreamTabQuestionList"
import StreamTabIFrame from "../components/stream/StreamTabIFrame"
import StreamChat from "../components/stream/StreamChat"

export default {
  name: "Stream",

  components: {
    StreamBar,
    StreamTabWebinar,
    StreamTabSlide,
    StreamTabQuestionDialog,
    StreamTabQuestionEditDialog,
    StreamTabQuestionList,
    StreamTabIFrame,
    StreamChat
  },

  mounted() {
    const it = this

    // 페이지 닫기 또는 리로드 시 발동. 단지 한번 경고할 뿐. API 호출 같은 건 불가능함
    window.onbeforeunload = () => true
    window.onunload = () => {
      it.io_emitConnectCount(-1)
      it.api_streamUserIO(it.info.id, 'out')
    }

    this.api_getStreamInfo()
  },

  beforeDestroy() {
    this.io_eventsOff()
    this.io_offDuplicateConnected()
    this.api_streamUserIO(this.info.id, 'out')

    window.onbeforeunload = undefined
    window.onunload = undefined
  },

  // 스트리밍 페이지에서 벗어날 때 라우팅 처리
  beforeRouteLeave (to, from, next) {
    const lang = this.gc_lang
    const text = this.gc_langText.stream.text.wantExit[lang]

    // 강제 변경이 필요할 경우 라우팅 변경후 종료
    if (this.forceRouting) {
      this.forceRouting = false
      return next()
    }

    // 스트리밍 사이트에서 나갈 것인지 묻는다
    this.gm_alertOpen({
      text,
      confirm: true,
      callback: (confirm) => confirm ? next() : next(false)
    })
  },

  data: () => ({
    // 이 페이지에서 벗어날 때 강제적으로 행해야 하는지 여부
    forceRouting: false,

    // 다중 접속 여부 채크 완료
    duplicateConnectChecked: false,

    // 스트리밍 접속자 카운트
    accessorCount: 0,

    // 스트리밍 정보 객체
    info: {},

    // 보여야 할 탭 인덱스
    tabIdx: 0,

    // 스트리밍 되어야 할 iframe src
    streamSrc: '',

    // 슬라이드 리스트
    slide: {
      file_id: -1,
      list: [],
    },

    // 채팅 관련된 객체
    chatPopup: {
      show: false,
      // 채팅창이 닫혀 있을 때 받은 메세지 개수 벳지
      badge: 0,
      // 채팅 메세지 배열 (null: 호출한 적 없음, Array: 호출한 적 있음)
      messages: null
    },

    // 상세 팝업 띄울 단일 이벤트에 대한 객체
    questionDialog: {
      show: false,
    },

    // 상세 팝업 띄울 단일 이벤트에 대한 객체
    questionDialogEdit: {
      show: false,
      question: {}, // 수정할 질문 객체
    },

    // 아이프레임으로 띄우야 하는 링크일 경우에 대한 객체
    linkDialog: {
      show: false,
      src: '',
    },

    // 관리자용 질문 탭 관련 객체
    question: {
      // 질문 모듈 아이디
      module_id: -1,

      // 발표자 모듈 아이디
      speaker_module_id: -1,

      // 질문 리스트
      list: [],

      // 현재 스트리밍 중에 보여야 할 질문
      active: ''
    }
  }),

  computed: {
    // 보여줘야 할 타입
    showType() {
      const tab = this.tabList[this.tabIdx] || {}

      return tab.type || ''
    },

    // 출력해야 할 탭 리스트
    tabList() {
      const list = this.info.modules || []
      const types = this.stream_tabType

      return list.filter( item => {
        return -1 < types.indexOf(item.type)
      })
    },

    // 대기 및 활성화 여부에 따른 텍스트 컬러 객체 (헥스 코드)
    colors() {
      return {
        default: this.info.maincolor,
        active: this.info.subcolor
      }
    }
  },

  methods: {
    // 텝 인덱스 세팅
    setTab(i) {
      // 잘못된 인덱스라 판단되는 경우 리턴
      if (i < 0) { return }

      const tab = this.tabList[i]

      // 주의 ================================
      // 각 조건문은 return 으로 끝날수도 있고 끝나지 않을 수도 있음

      // 외부 링크 이동
      if ( tab.type === 'link' ) {
        const hasProtocol = tab.href.search(/^http[s]?:\/\//) !== -1
        const href = `${ hasProtocol ? '' : '//' }${ tab.href }`

        // iFrame 으로 링크를 띄워야 할 경우
        if (tab.link_type === 'in') {
          this.linkDialog.show = true
          this.linkDialog.src = href
        }

        // 새창으로 링크를 띄워야 할 경우
        else {
          window.open( href, '_blank' )
        }

        return
      }
      // 질문 탭 사용자 등급이 최고관리자가 아닐 경우
      else if ( tab.type === 'questiontospeaker' && this.gc_user.admin_level !== 0 ) {
        return this.questionDialog.show = true
      }
      // 웨비나 탭 선택시 아직 src 설정이 안되어 있을 경우
      else if ( tab.type === 'webinar' && !this.streamSrc ) {
        this.streamSrc = tab.href
      }
      // 슬라이드 탭 선택시 && 이전 선택 슬라이드 파일 아이디가 다를 경우
      else if ( tab.type === 'slide' && this.slide.file_id !== tab.file.id) {
        const fileKeys = Object.keys( tab.file ).filter( key => key.match('photo') )
        const list = []

        fileKeys.map( key => {
          const href = tab.file[key]

          href && list.push(href)
        })

        this.slide.file_id = tab.file.id
        this.slide.list = list
      }

      // 아닐 경우 텝 인덱스 설정 후 종료
      this.tabIdx = i
    },

    // 질문 노출 요청
    showQuestion(i) {
      const question = this.question.list[i]

      // 질문 활성화 시 이전 질문리스트 모두 비활성화
      // api 로 리스트 받아오지만 그 간격간의 ux 개선을 위함
      if (!question.is_use_subtitle) {
        this.question.list.forEach( item => item.is_use_subtitle = false )
      }

      question.is_use_subtitle = !question.is_use_subtitle
      this.api_questionActiveToggle(question.id)
    },

    // 질문 필터 토글 요청
    showFilter(i) {
      const question = this.question.list[i]

      question.is_filtering = !question.is_filtering
      this.api_questionFilterToggle(question.id)
    },

    // 질문 채크박스(우측) 토글 요청
    showChecked(i) {
      const question = this.question.list[i]

      question.is_checked = !question.is_checked
      this.api_questionCheckedToggle(question.id)
    },

    // 질문 수정 팝업 열기
    questionEditOpen(i) {
      const question = this.question.list[i]

      this.questionDialogEdit.question = question
      this.questionDialogEdit.show = true
    },

    // 질문 수정 팝업 닫기
    questionEditClose() {
      this.questionDialogEdit.show = false
    },

    // 질문 팝업 닫기
    questionClose() {
      this.questionDialog.show = false
    },

    // 링크 팝업 닫기
    linkClose() {
      this.linkDialog.show = false
    },

    // 채팅창 토글
    toggleChat() {
      const show = this.chatPopup.show

      // 닫혀있음 -> 열림 으로 상태 변환시
      if (!show) {
        this.chatPopup.badge = 0
      }

      this.chatPopup.show = !show
    },

    // 서버에서 응답받는 것이 아닌 로컬에서 입력한 메세지 채팅창에 입력
    pushLocalMessage(content = '') {
      const user_id = this.gc_user.id
      const name = this.gc_user.name
      const messages = this.chatPopup.messages

      if (!messages) { return }

      const msg = {
        content,
        user_id,
        user: { name },
        local: true
      }

      messages.push(msg)
    }
  }
}
</script>

<style lang="sass" scoped>
.c-bd-b
  border-bottom: 1px solid #FFFFFF !important
</style>