<template>
  <v-dialog
      v-model="show"
      transition="dialog-bottom-transition"
      width="480"
      persistent
      scrollable
  >
    <!--  발표자 리스트 조회중  -->
    <v-card
      v-if="requestingSpeaker"
      tile
    >
      <div class="d-flex ma-16 justify-center">
        <v-progress-circular
          class="my-16"
          color="cyan"
          indeterminate
        />
      </div>
    </v-card>

    <!--  질문 알림창  -->
    <v-card
      v-else
      tile
    >

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          class="c-tt-none"
          color="black"
          @click="show = false"
          :disabled="requesting"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-title class="headline mb-2 py-0">
        Question
      </v-card-title>

      <!--  질문 입력란  -->
      <v-card-text class="pt-2 pb-0">
        <v-form
          ref="form"
          v-model="formValid"
        >

          <!--  발표자 선택  -->
          <v-select
            v-model="form.presenter"
            :label="gc_langText.stream.text.selectPresenter[gc_lang]"
            :rules="[gc_rules.required]"
            :disabled="requesting"
            :items="list"
            outlined
            dense
          />

          <!--  질문 내용  -->
          <v-textarea
            v-model="form.content"
            class="rounded-0"
            :label="gc_langText.stream.text.questionContent[gc_lang]"
            :rules="[gc_rules.required]"
            :disabled="requesting"
            outlined
            dense
          />

        </v-form>
      </v-card-text>

      <!--  전송 버튼  -->
      <v-card-actions class="justify-center pt-0 pb-4">
        <v-btn
          class="px-16 c-tt-none"
          color="cyan"
          elevation="0"
          :loading="requesting"
          @click="submit"
          large
          dark
          tile
        >
          {{ gc_langText.stream.btn.submit[gc_lang] }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StreamTabQuestionDialog",

  props: {
    _show: {
      type: Boolean,
      default: false
    },

    module_id: {
      type: Number,
      default: -1
    },

    speaker_module_id: {
      type: Number,
      default: -1
    }
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,

    // 질문 전송중
    requesting: false,

    // 발표자 리스트 요청중
    requestingSpeaker: true,

    // 질문 받을 발표자 선택 리스트
    listOrigin: [],

    // 입력란
    form: {
      // 질문 대상
      presenter: '',

      // 질문 내용
      content: '',
    },

    // 입력란 유효성 검사
    formValid: true
  }),

  computed: {
    list() {
      const lang = this.gc_lang_prefix

      return this.listOrigin.map( item => ({
        text: item[`name${lang}`],
        value: item.id
      }))
    }
  },

  watch: {
    _show(val) {
      const form = this.$refs.form
      this.show = val

      val && this.api_getQuestionSpeaker()
      form && form.reset()
    },

    show(val) {
      !val && this.$emit('questionClose')
    }
  },

  methods: {
    // 질문 전송
    submit() {
      this.$refs.form.validate()

      this.formValid && this.api_submitQuestion()
    }
  }
}
</script>

<style scoped>

</style>