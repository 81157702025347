<template>
  <v-card
    class="transparent c-w-100-stream"
    flat
    tile
  >

    <!--  슬라이드  -->
    <v-carousel
      v-model="page"
      height="auto"
      :continuous="false"
      hide-delimiters
    >
      <!--  이미지 사전 로드를 위해 아래 두 요소모두에 eager 속성 추가  -->
      <v-carousel-item
        v-for="(item, i) in slide"
        :key="`stream-slide-${i}`"
        eager
      >
        <v-img
          :src="item"
          eager
        />
      </v-carousel-item>
    </v-carousel>

    <!--  페이징  -->
    <v-row>
      <v-col class="mt-3 text-center">
        <v-text-field
          v-model="pageInput"
          :suffix="`/ ${slide.length}`"
          class="d-inline-block rounded-0"
          background-color="white"
          type="number"
          :min="1"
          :max="slide.length"
          :step="1"
          hide-details
          outlined
          dense
        />
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
export default {
  name: "StreamTabSlide",

  props: {
    slide: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    // 슬라이드 페이지
    page: 0,

    // 슬라이드 페이지 시각적 카운트 값
    pageInput: 1
  }),

  watch: {
    // 새로운 슬라이드 배열로 갱신 된 경우 페이징 초기화
    slide() {
      this.page = 0
    },

    // 현재 페이지 index 변경시 시각적 페이징 번호 값 변경
    page(num, old) {
      old = Number(old)
      num = Number(num)

      if (old !== num) {
        this.pageInput = num + 1
      }
    },

    // 사용자 입력에 의한 페이지 변경시 현재 페이지 index 값 변경
    pageInput(num, old) {
      old = Number(old)
      num = Math.floor( Number(num) )
      num = 1 > num ? 1 :
        num > this.slide.length ? this.slide.length : num

      if (old === num) { return }

      this.page = num - 1
    }
  }
}
</script>

<style lang="sass" scoped>
.c-page-controller
  position: absolute
  top: .5rem
  right: .5rem
  z-index: 1
</style>